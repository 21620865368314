const initialState = {
  LOGIN: {
    loginDetails: undefined,
    error: undefined,
  },
  CUSTOMER: {
    customersList: undefined,
    error: undefined,
  },
  LOADING: {
    loading: 0,
  },
  MODAL: {
    key: undefined,
    props: undefined,
  },
};

export default initialState;
