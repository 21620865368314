/* eslint-disable import/no-anonymous-default-export */
import React, { useCallback, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { CaretDownOutlined } from "@ant-design/icons";
import { Layout, Menu, Dropdown, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";

import CONFIG from "../utils/config";
import Overlay from "../components/overlay";
import {
  errorNotification,
  filterNavItems,
  getPathKey,
  getToken,
  setToken,
} from "../utils/functions";

import logo from "../assets/images/logo.png";
import profilePic from "../assets/images/profile-pic.png";
import Loader from "../components/loader";
import { fetchDetails } from "../redux-toolkit/slice/login/asyncThunk";
import loginDataSelector from "../redux-toolkit/selectors/loginData";
import loadingDataSelector from "../redux-toolkit/selectors/loadingData";

const { Header, Content, Sider } = Layout;

export default function () {
  const {
    ALLOWED_ACCESS,
    MENU_ITEMS,
    PROFILE_ITEMS,
    TOKEN: { ACCESS_TOKEN, REFRESH_TOKEN },
    ROUTES: { LOGIN, UNAUTHORISED },
  } = CONFIG;

  const accessToken = getToken(ACCESS_TOKEN);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loginDetails, error } = loginDataSelector(useSelector);

  const { loading } = loadingDataSelector(useSelector);

  const [selectedMenu, setSelectedMenu] = useState(null);

  const { name, photo_url, role } = loginDetails || {};

  const handleMenuSelect = ({ key }) => {
    setSelectedMenu(key);
    navigate(LOGIN.path + key);
  };

  const handleLogOut = () => {
    navigate(LOGIN.path);
    setToken(ACCESS_TOKEN, "");
    setToken(REFRESH_TOKEN, "");
  };

  const handleViewProfile = () => {};

  const fetchLoginDetails = useCallback(() => {
    dispatch(fetchDetails());
  }, [dispatch]);

  useEffect(() => {
    if (accessToken) {
      if (!loginDetails) fetchLoginDetails();
    } else navigate(LOGIN.path);
  }, [LOGIN, accessToken, loginDetails, fetchLoginDetails, navigate]);

  useEffect(() => {
    if (error) errorNotification(error);
  }, [error]);

  useEffect(() => {
    let key = getPathKey();
    if (role) {
      let isAccessAllowed = ALLOWED_ACCESS[role].includes(key);
      isAccessAllowed ? setSelectedMenu(key) : navigate(UNAUTHORISED.path);
    }
  }, [ALLOWED_ACCESS, UNAUTHORISED, role, navigate]);

  return (
    <Layout hasSider>
      <Overlay show={loading}>
        <Loader />
      </Overlay>
      <Sider className="sidebar">
        <img className="logo" src={logo} alt="logo" />
        <div className="demo-logo-vertical" />
        <Menu
          className="menu"
          selectedKeys={[selectedMenu]}
          mode="inline"
          items={filterNavItems(MENU_ITEMS(selectedMenu), ALLOWED_ACCESS[role])}
          onSelect={handleMenuSelect}
        />
      </Sider>
      <Layout className="layout site-layout">
        <Header>
          <div>
            <Dropdown
              className="user-dropdown"
              menu={{
                items: PROFILE_ITEMS({ handleViewProfile, handleLogOut }),
              }}
            >
              <div>
                <span className="user-image">
                  <img src={photo_url ?? profilePic} alt="profilePic" />
                </span>
                <Space>
                  {name ?? "Admin"}
                  <CaretDownOutlined />
                </Space>
              </div>
            </Dropdown>
          </div>
        </Header>
        {selectedMenu && (
          <Content>
            <Outlet />
          </Content>
        )}
      </Layout>
    </Layout>
  );
}
