import { Button, Modal, Table } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../redux-toolkit/slice/modal";
import { fetchCustomerLogs } from "../../../redux-toolkit/slice/customer/asyncThunk";
import { withKeyDataSet } from "../../../utils/functions";
import CONFIG from "../../../utils/config";

function ErrorLogsModal({ customerData }) {
  const {
    LABELS: { ID },
    ERROR_LOGS_COLUMNS,
  } = CONFIG;

  const dispatch = useDispatch();

  const [logs, setLogs] = useState([]);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const fetchLogs = useCallback(async () => {
    let data = await fetchCustomerLogs(dispatch, customerData);
    setLogs(data);
  }, [customerData, dispatch]);

  useEffect(() => {
    fetchLogs();
  }, [fetchLogs]);

  return (
    <Modal
      key={`${customerData.name} Error Logs`}
      title={`${customerData.name} Error Logs`}
      centered
      className="common-modal"
      open={true}
      width={700}
      onCancel={handleCloseModal}
      footer={
        <Button className="primary-outline" onClick={handleCloseModal}>
          Cancel
        </Button>
      }
    >
      <Table
        className="cmn-table"
        columns={ERROR_LOGS_COLUMNS}
        dataSource={withKeyDataSet(ID, logs)}
        scroll={{ y: "calc(70vh - 60px)" }}
        pagination={false}
      />
    </Modal>
  );
}

export default ErrorLogsModal;
