import React, { useEffect } from "react";
import { Col, Row, Image, Form } from "antd";

import CONFIG from "../../utils/config";
import logo from "../../assets/images/logo.png";
import { getToken } from "../../utils/functions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Overlay from "../../components/overlay";
import Loader from "../../components/loader";
import loadingDataSelector from "../../redux-toolkit/selectors/loadingData";
import GoogleButton from "react-google-button";
import { sendGoogleCode } from "../../redux-toolkit/slice/login/asyncThunk";

function Login() {
  const {
    GOOGLE_AUTHORISATION_URL,
    ROUTES: { CUSTOMERS },
    TOKEN: { ACCESS_TOKEN },
    LABELS: {
      LOGIN: { CODE },
    },
  } = CONFIG;

  const access_token = getToken(ACCESS_TOKEN);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading } = loadingDataSelector(useSelector);

  const handleGoogleLogin = () => {
    window.location.href = GOOGLE_AUTHORISATION_URL;
  };

  useEffect(() => {
    if (access_token) navigate(CUSTOMERS.path);
  }, [access_token, navigate, CUSTOMERS]);

  useEffect(() => {
    const authorizationCode = new URLSearchParams(window.location.search).get(
      CODE
    );

    if (authorizationCode)
      dispatch(
        sendGoogleCode({
          code: authorizationCode,
          callback: () => {
            navigate(CUSTOMERS.path);
          },
        })
      );
  }, [CODE, CUSTOMERS, dispatch, navigate]);

  return (
    <Row justify="center" align="middle" className="login-form">
      <Overlay show={loading}>
        <Loader />
      </Overlay>
      <Col span={12}>
        <div className="login-form-content">
          <div justify="center" align="middle">
            <Image
              src={logo}
              alt="logo"
              width={150}
              className="logo"
              preview={false}
            />
          </div>
          <Form>
            <Form.Item className="googleLogin">
              <GoogleButton
                className="w-100"
                type="dark"
                onClick={handleGoogleLogin}
              />
            </Form.Item>
          </Form>
        </div>
      </Col>
    </Row>
  );
}

export default Login;
