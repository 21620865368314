import { createAsyncThunk } from "@reduxjs/toolkit";
import CONFIG from "../../../utils/config";
import { beginAPICall, endAPICall } from "../loading";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../../api/baseAPI";
import {
  errorNotification,
  successNotification,
} from "../../../utils/functions";

let initialObject = {
  callback: () => {},
};

export const fetchCustomers = createAsyncThunk(
  "customer/fetchCustomers",
  async ({ callback } = initialObject, { dispatch }) => {
    let {
      API_URLS: { FETCH_CUSTOMERS },
    } = CONFIG;
    try {
      dispatch(beginAPICall());
      let { data } = await getRequest(FETCH_CUSTOMERS, { service: "CUSTOMER" });
      successNotification({
        title: "Customers Fetched",
        message: "All Customers has been successfully fetched",
      });
      return data;
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Failed to fetch users",
      });
    } finally {
      dispatch(endAPICall());
      callback();
    }
  }
);

export const createCustomer = createAsyncThunk(
  "customer/createCustomer",
  async ({ payload, callback = () => {} }, { dispatch }) => {
    let {
      API_URLS: { CREATE_CUSTOMERS },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let {
        data: { data },
      } = await postRequest(CREATE_CUSTOMERS, payload, { service: "CUSTOMER" });
      successNotification({
        title: "Customer Created",
        message: "Customer has been successfully created",
      });
      return data;
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Failed to create customer",
      });
    } finally {
      callback();
      dispatch(endAPICall());
    }
  }
);

export const editCustomer = createAsyncThunk(
  "customer/editCustomer",
  async ({ id, payload, callback = () => {} }, { dispatch }) => {
    let {
      API_URLS: { CREATE_CUSTOMERS },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let { data } = await putRequest(CREATE_CUSTOMERS + `${id}/`, payload, {
        service: "CUSTOMER",
      });
      successNotification({
        title: "Customer Edited",
        message: "Customer has been successfully edited",
      });
      return data;
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Failed to edit Customer",
      });
    } finally {
      callback();
      dispatch(endAPICall());
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  "customer/deleteCustomer",
  async ({ id, callback = () => {} }, { dispatch }) => {
    let {
      API_URLS: { CREATE_CUSTOMERS },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let {
        data: { data },
      } = await deleteRequest(CREATE_CUSTOMERS + `${id}/`, {
        service: "CUSTOMER",
      });
      successNotification({
        title: "Customer Deleted",
        message: "Customer has been successfully deleted",
      });
      return data;
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Failed to delete Customer",
      });
    } finally {
      callback();
      dispatch(endAPICall());
    }
  }
);

export const customerBatchUpdate = createAsyncThunk(
  "customer/customerBatchUpdate",
  async ({ payload, callback }, { dispatch }) => {
    let {
      API_URLS: { CUSTOMER_BATCH_UPDATE },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let { data } = await putRequest(CUSTOMER_BATCH_UPDATE, payload, {
        service: "CUSTOMER",
      });
      successNotification({
        title: "Customer Batch Updated",
        message: "Customer batch has been successfully updated",
      });
      return data;
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Failed to update Customer batch",
      });
    } finally {
      callback();
      dispatch(endAPICall());
    }
  }
);

export const sendMail = createAsyncThunk(
  "customer/sendMail",
  async ({ payload, callback = () => {} }, { dispatch }) => {
    let {
      API_URLS: { SEND_CUSTOMER_MAIL },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      await postRequest(SEND_CUSTOMER_MAIL, payload, { service: "CUSTOMER" });
      successNotification({
        title: "Mail Sent",
        message: "Customer Setup mail has been successfully sent",
      });
    } catch ({ response }) {
      let {
        data: { message },
      } = response;
      errorNotification({
        title: "API Error",
        message,
      });
    } finally {
      callback();
      dispatch(endAPICall());
    }
  }
);

export const cloneCustomer = createAsyncThunk(
  "customer/cloneCustomer",
  async ({ customer_id, callback }, { dispatch }) => {
    let {
      API_URLS: { CLONE_CUSTOMER },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let {
        data: { token },
      } = await getRequest(CLONE_CUSTOMER, {
        service: "CUSTOMER",
        params: { customer_id },
      });
      callback(token);
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Failed to clone customer",
      });
    } finally {
      dispatch(endAPICall());
    }
  }
);

export const createSuperAdminForCustomer = createAsyncThunk(
  "customer/createSuperAdminForCustomer",
  async ({ payload, callback = () => {} }, { dispatch }) => {
    let {
      API_URLS: { CREATE_SUPER_ADMIN },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let {
        data: { data },
      } = await postRequest(CREATE_SUPER_ADMIN, payload, {
        service: "CUSTOMER",
      });
      return data;
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Failed to create super admin for customer",
      });
      throw new Error("Failed to create super admin for customer");
    } finally {
      callback();
      dispatch(endAPICall());
    }
  }
);

export const fetchCustomerLogs = async (dispatch, { id }) => {
  let {
    API_URLS: { FETCH_ERROR_LOGS },
  } = CONFIG;
  try {
    dispatch(beginAPICall());
    let { data } = await getRequest(FETCH_ERROR_LOGS + id + "/", {
      service: "ERRORS",
    });
    return data;
  } catch (error) {
    errorNotification({
      title: "API Error",
      message: "Failed to fetch customer error logs",
    });
  } finally {
    dispatch(endAPICall());
  }
};
