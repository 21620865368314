import { createAsyncThunk } from "@reduxjs/toolkit";

import CONFIG from "../../../utils/config";
import { getRequest, postRequest } from "../../../api/baseAPI";
import { beginAPICall, endAPICall } from "../loading";
import { errorNotification, setToken } from "../../../utils/functions";

export const sendGoogleCode = createAsyncThunk(
  "login/sendGoogleCode",
  async ({ code, callback }, { dispatch }) => {
    let reqData = new FormData();
    reqData.append("code", code);

    try {
      dispatch(beginAPICall());
      let { data } = await postRequest(
        CONFIG.API_URLS.GOOGLE_ACCESS_TOKEN,
        reqData,
        { service: "SUPPORT" }
      );
      return data;
    } catch ({ response }) {
      const { data } = response;
      throw new Error(JSON.stringify(data));
    } finally {
      dispatch(endAPICall());
      callback();
    }
  }
);

export const fetchDetails = createAsyncThunk(
  "login/fetchDetails",
  async (_, { dispatch }) => {
    try {
      dispatch(beginAPICall());
      let { data } = await getRequest(CONFIG.API_URLS.FETCH_DETAILS, {
        service: "SUPPORT",
      });
      return data;
    } catch (response) {
      errorNotification({
        title: "API Failed",
        message: "Failed to fetch User Details",
      });
      setToken(CONFIG.TOKEN.ACCESS_TOKEN, "");
      setToken(CONFIG.TOKEN.REFRESH_TOKEN, "");
    } finally {
      dispatch(endAPICall());
    }
  }
);
