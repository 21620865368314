import { createSlice } from "@reduxjs/toolkit";

import INITIAL_STATE from "../../initialState";
import { fetchDetails, sendGoogleCode } from "./asyncThunk";
import { setToken } from "../../../utils/functions";
import CONFIG from "../../../utils/config";

export const loginSlice = createSlice({
  name: "login",
  initialState: INITIAL_STATE.LOGIN,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendGoogleCode.fulfilled, (state, { payload }) => {
      const { access_token, refresh_token, google_profile_data } = payload;
      state.loginDetails = google_profile_data;
      setToken(CONFIG.TOKEN.ACCESS_TOKEN, access_token);
      setToken(CONFIG.TOKEN.REFRESH_TOKEN, refresh_token);
    });

    builder.addCase(sendGoogleCode.rejected, (state, { error }) => {
      state.error = JSON.parse(error.message);
    });

    builder.addCase(fetchDetails.fulfilled, (state, { payload }) => {
      state.error = null;
      state.loginDetails = payload;
    });
  },
});

export default loginSlice.reducer;
