import { combineReducers } from "@reduxjs/toolkit";

import loginReducer from "./login";
import loadingReducer from "./loading";
import modalReducer from "./modal";
import customerReducer from "./customer";

export default combineReducers({
  loginReducer,
  modalReducer,
  loadingReducer,
  customerReducer,
});
