import Icon from "../../components/icon";
import {
  UsergroupAddOutlined,
  MailOutlined,
  SwitcherOutlined,
  HistoryOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

import { Dropdown, Switch, Tag, Tooltip } from "antd";

import alert from "../../assets/images/alert.png";
import tick from "../../assets/images/tick.png";
import info from "../../assets/images/info.png";
import trash from "../../assets/images/delete.png";
import setting from "../../assets/images/setting.svg";
import CustomerModal from "../../modal/components/customer";
import moment from "moment";
import ErrorLogsModal from "../../modal/components/errorLogs";
import { uppercaseFirstLetter } from "../functions";
import CreateSuperAdminModal from "../../modal/components/createSuperAdmin";

const { REACT_APP_CLIENT_ID, REACT_APP_GOOGLE_REDIRECT_URI } = process.env;

const CONFIG = {
  ROUTES: {
    LOGIN: { path: "/" },
    CUSTOMERS: { path: "/customers" },
    NOT_FOUND: {
      path: "*",
    },
    UNAUTHORISED: {
      path: "/unauthorised",
    },
  },
  ALLOWED_ACCESS: { Support: ["customers"] },
  GOOGLE_AUTHORISATION_URL: `https://accounts.google.com/o/oauth2/auth?client_id=${REACT_APP_CLIENT_ID}&scope=openid%20profile%20email%20openid%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&redirect_uri=${REACT_APP_GOOGLE_REDIRECT_URI}&prompt=consent&access_type=offline&response_type=code&include_granted_scopes=true&enable_granular_consent=true&service=lso&o2v=2&flowName=GeneralOAuthFlow&state=google`,
  API_URLS: {
    GOOGLE_ACCESS_TOKEN: "support/oauth/token/",
    FETCH_DETAILS: "support/data/",
    FETCH_CUSTOMERS: "/customers/",
    CREATE_CUSTOMERS: "/customers/",
    CREATE_SUPER_ADMIN: "customer/admin/",
    CUSTOMER_BATCH_UPDATE: "/customer/batch/",
    SEND_CUSTOMER_MAIL: "/customer/invite/",
    CLONE_CUSTOMER: "/customer/token/",
    FETCH_ERROR_LOGS: "",
  },
  NOTIFICATION_OBJECT: {
    ERROR: alert,
    SUCCESS: tick,
    PENDING: info,
  },
  ERRORS: {
    AUTH_API: {
      "expired authorization code": {
        message: "Auth API Failed",
        description: "Access Token is expired",
      },
      "invalid authorization code": {
        message: "Auth API Failed",
        description: "Invalid authorization code.",
      },
    },
    REQUIRED: "Required field - Please provide value",
    REQUIRED_IMAGE: "Required field - Please provide profile image",
    BOOLEAN_VALUE: "Please provide boolean value only",
    MERGE_FIELD: "Please provide valid merge field",
  },
  TOKEN: {
    ACCESS_TOKEN: "customer_access_token",
    REFRESH_TOKEN: "customer_refresh_token",
    KWIPO_ADMIN_ACCESS_TOKEN: "access_token",
    KWIPO_ADMIN_REFRESH_TOKEN: "refresh_token",
  },
  LABELS: {
    ID: "id",
    VALIDATION: {
      REQUIRED: "required",
      STRING_STATUS: "stringStatus",
    },
    LOGIN: {
      WELCOME: "Welcome",
      LOGIN_NOW: "Please login to continue",
      TITLE: "Login",
      USERNAME_OR_EMAIL: "Username or Email",
      PASSWORD: "Password",
      CONTINUE: "Continue",
      SIGNUP: "Sign Up",
      SALESFORCE: "Continue with Salesforce",
      SUB_TITLE: "Acceess with your Salesforce Account",
      IMAGE_TEXT_FIRST: "Good works are waiting for you",
      IMAGE_TEXT_SECOND: "Login Now!!!",
      CODE: "code",
      ADMIN_EMAIL: "adminEmail",
      ADMIN_ID: "adminId",
    },
    REDIRECT_URL: {
      TITLE: "Processing Salesforce authentication",
    },
    USER: {
      NAME: "name",
      ALIAS: "alias",
      EMAIL: "email",
      TITLE: "title",
      USERNAME: "username",
      ADMIN: "Admin",
      SUPER_ADMIN: "Super Admin",
      USER: "User",
    },
  },
  MENU_ITEMS: (activeKey) => [
    {
      label: "Customers",
      key: "customers",
      icon: (
        <Icon
          Component={UsergroupAddOutlined}
          fill={activeKey === "customers" ? "white" : "#4a3567"}
        />
      ),
    },
  ],
  PROFILE_ITEMS: ({ handleViewProfile, handleLogOut }) => [
    {
      key: "profile",
      label: <div onClick={handleViewProfile}>Profile</div>,
    },
    {
      key: "logout",
      danger: true,
      label: <div onClick={handleLogOut}>Log out</div>,
    },
  ],
  USER_INITIAL_COLUMNS: [
    "username",
    "name",
    "is_active",
    "alias",
    "title",
    "email",
  ],
  MODALS: {
    CUSTOMER: {
      key: "CUSTOMER",
      component: CustomerModal,
    },
    ERROR_LOGS: {
      key: "ERROR_LOGS",
      component: ErrorLogsModal,
    },
    CREATE_SUPER_ADMIN: {
      key: "CREATE_SUPER_ADMIN",
      component: CreateSuperAdminModal,
    },
  },
  ERROR_LOGS_COLUMNS: [
    {
      title: "Occured at Time",
      dataIndex: "created_at",
      sorter: {
        compare: (a, b) => {
          return moment(a.created_at).diff(b.created_at);
        },
      },
      render: (val) => {
        return moment.utc(val).local().format("MMM, DD YYYY hh:mm A");
      },
    },
    { title: "Status", dataIndex: "status" },
    { title: "Service", dataIndex: "service" },
  ],
  CUSTOMER_COLUMN: [
    {
      title: "ID",
      dataIndex: "id",
      width: 120,
      fixed: "left",
      render: (id, { is_invite_sent }) => (
        <span className="capitalise">
          {!is_invite_sent && (
            <Tooltip title="Setup user by sending the mail">
              <span className="dot" />
            </Tooltip>
          )}
          {id}
        </span>
      ),
    },
    {
      title: "Customer Name",
      dataIndex: "name",
      width: 300,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
    },
    {
      title: "Account Stage",
      dataIndex: "subscription_stage",
      width: 200,
      render: (val) => {
        return uppercaseFirstLetter(val);
      },
    },
    {
      title: "Subscription Start",
      dataIndex: "subscription_start",
      width: 250,
      render: (val) => {
        if (!val) return "";

        let StartDate = +val * 1000;
        return moment.utc(StartDate).local().format("ddd, DD MMM YYYY hh:mm A");
      },
    },
    {
      title: "Renewal Term",
      dataIndex: "subscription_renewal_term",
      width: 150,
      render: (val) => {
        return uppercaseFirstLetter(val);
      },
    },
    {
      title: "Renewal Date",
      dataIndex: "subscription_end",
      width: 250,
      render: (val) => {
        if (!val) return "";

        let RenewalDate = +val * 1000;
        return moment
          .utc(RenewalDate)
          .local()
          .format("ddd, DD MMM YYYY hh:mm A");
      },
    },
    {
      title: "Kwipo Status",
      dataIndex: "status",
      width: 200,
      render: (value, _, __, { CUSTOMER_COLUMN_BADGE_COLOR }) => {
        return <Tag color={CUSTOMER_COLUMN_BADGE_COLOR[value]}>{value}</Tag>;
      },
    },
    {
      title: "Logging Status",
      dataIndex: "is_logging_allowed",
      width: 150,
      render: (value, { id }, _, { handleLoggingStatus }) => {
        return (
          <Switch
            checked={value}
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            onChange={(is_logging_allowed) =>
              handleLoggingStatus({ is_logging_allowed, id })
            }
          />
        );
      },
    },
    {
      title: "Actions",
      width: 150,
      render: (
        _,
        record,
        __,
        {
          handleMessageClick,
          handleSettingClick,
          handleErrorLogClick,
          handleDeleteClick,
          handleAddSuperAdmin,
          handleClone,
        }
      ) => {
        const items = [
          {
            label: (
              <span onClick={() => handleSettingClick(record.id, "Active")}>
                Activate Account
              </span>
            ),
            key: "Active",
          },
          {
            label: (
              <span onClick={() => handleSettingClick(record.id, "Suspended")}>
                Suspend Account
              </span>
            ),
            key: "Suspended",
          },
          {
            label: (
              <span
                onClick={() =>
                  handleSettingClick(record.id, "Scheduled for Deletion")
                }
              >
                Delete Account
              </span>
            ),
            key: "Deleted",
          },
        ];

        return (
          <span style={{ display: "flex", justifyContent: "center" }}>
            <HistoryOutlined
              style={{ color: "#868685" }}
              className="mr-1 cursor-pointer"
              onClick={() => handleErrorLogClick(record)}
            />
            {record.is_invite_sent ? (
              <SwitcherOutlined
                style={{ color: "#868685" }}
                className="mr-1 cursor-pointer"
                onClick={() => handleClone(record)}
              />
            ) : (
              <Tooltip title="Please send mail to the customer to proceed.">
                <SwitcherOutlined
                  style={{ color: "#868685" }}
                  className="mr-1 cursor-pointer"
                />
              </Tooltip>
            )}

            {record.subscription_details ? (
              record.isSuperAdminAdded ? (
                <MailOutlined
                  style={{ color: "#868685" }}
                  className="mr-1 cursor-pointer"
                  onClick={() => handleMessageClick(record)}
                />
              ) : (
                <Tooltip title="Please designate a Super Admin to configure the Admin Portal">
                  <PlusCircleOutlined
                    style={{ color: "#868685" }}
                    className="mr-1 cursor-pointer"
                    onClick={() => handleAddSuperAdmin(record)}
                  />
                </Tooltip>
              )
            ) : record.isSuperAdminAdded ? (
              <Tooltip title="Please add Subscription to proceed">
                <MailOutlined
                  style={{ color: "#868685" }}
                  className="mr-1 cursor-pointer"
                />
              </Tooltip>
            ) : (
              <Tooltip title="Please add Subscription to proceed">
                <PlusCircleOutlined
                  style={{ color: "#868685" }}
                  className="mr-1 cursor-pointer"
                />
              </Tooltip>
            )}

            <Tooltip
              title={
                record.subscription_details
                  ? ""
                  : "Please add Subscription to proceed"
              }
            >
              <Dropdown
                menu={{ items }}
                trigger={["click"]}
                placement="left"
                disabled={!record.subscription_details}
              >
                <img
                  src={setting}
                  style={{ height: 16, width: 16, marginRight: 8 }}
                  alt="setting"
                  className="mr-1 cursor-pointer"
                />
              </Dropdown>
            </Tooltip>

            <img
              src={trash}
              className="mr-1 cursor-pointer"
              style={{ height: 16, width: 16, marginRight: 8 }}
              alt="trash"
              onClick={() => {
                handleDeleteClick(record.id, record.type);
              }}
            />
          </span>
        );
      },
    },
  ],
  DEFAULT_PROFILE: {
    name: "Annonymous",
  },
  CUSTOMER_COLUMN_BADGE_COLOR: {
    Active: "green",
    Suspended: "gold",
    "Scheduled for Deletion": "red",
  },
  CUSTOMER_BULK_ACTION: [
    { label: "Activate Account", key: "Active", value: "Active" },
    { label: "Suspend Account", key: "Suspended", value: "Suspended" },
    {
      label: "Delete Account",
      key: "Scheduled for Deletion",
      value: "Scheduled for Deletion",
    },
  ],
};

export default CONFIG;
