import { Button, Col, Form, Input, Modal, Row } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../redux-toolkit/slice/modal";
import { createSuperAdminForCustomer } from "../../../redux-toolkit/slice/customer/asyncThunk";

function CreateSuperAdminModal({ customer_id }) {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleSave = async () => {
    let valueObj = form.getFieldValue();

    try {
      await form.validateFields(["first_name", "last_name", "email"]);
      let payload = { customer_id, ...valueObj };
      dispatch(
        createSuperAdminForCustomer({ payload, callback: handleCloseModal })
      );
    } catch (e) {}
  };

  return (
    <Modal
      key={"Create New Super Admin"}
      title={"Create New Super Admin"}
      centered
      className="common-modal"
      open={true}
      width={700}
      onCancel={handleCloseModal}
      footer={
        <div className="footer-btns">
          <Button className="primary-solid" onClick={handleSave}>
            Save
          </Button>
          <Button className="primary-outline" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      }
    >
      <Form form={form} name="form_item_path" layout="vertical">
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Row gutter={[12, 12]} className="mb-1">
              <Col xs={24} md={12}>
                <Form.Item
                  label="First Name"
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: "Please input First Name",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Last Name"
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      message: "Please input Last Name",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24}>
            <Row gutter={[12, 12]} className="mb-1">
              <Col xs={24} md={24}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail",
                    },
                    {
                      required: true,
                      message: "Please input email",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default CreateSuperAdminModal;
