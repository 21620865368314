/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { Route, Routes } from "react-router-dom";

import Login from "../pages/login";
import CONFIG from "../utils/config";
import Layout from "../layout";

import PageNotFound from "../pages/notFound";
import Unauthorised from "../pages/unauthorised";
import Customers from "../pages/customers";

export default function () {
  const {
    ROUTES: { LOGIN, CUSTOMERS, NOT_FOUND, UNAUTHORISED },
  } = CONFIG;

  return (
    <Routes>
      <Route exact path={LOGIN.path} element={<Login />} />
      <Route element={<Layout />}>
        <Route path={CUSTOMERS.path} element={<Customers />} />
      </Route>
      <Route path={NOT_FOUND.path} element={<PageNotFound />} />
      <Route path={UNAUTHORISED.path} element={<Unauthorised />} />
    </Routes>
  );
}
