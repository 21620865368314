import { Button, Col, Row, Table, Input, Select } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  batchStatusPayload,
  columnAndComponentFunctionReference,
  getCustomerMailPayload,
  withKeyDataSet,
} from "../../utils/functions";
import CONFIG from "../../utils/config";
import { useDispatch, useSelector } from "react-redux";

import { openModal } from "../../redux-toolkit/slice/modal";
import {
  cloneCustomer,
  customerBatchUpdate,
  deleteCustomer,
  editCustomer,
  fetchCustomers,
  sendMail,
} from "../../redux-toolkit/slice/customer/asyncThunk";
import customerDataSelector from "../../redux-toolkit/selectors/customerData";
import { updateCustomerList } from "../../redux-toolkit/slice/customer";

const { Search } = Input;

function Customers() {
  const {
    CUSTOMER_COLUMN,
    CUSTOMER_COLUMN_BADGE_COLOR,
    MODALS: { CUSTOMER, ERROR_LOGS, CREATE_SUPER_ADMIN },
    LABELS: { ID },
    CUSTOMER_BULK_ACTION,
  } = CONFIG;

  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [batchStatus, setBatchStatus] = useState("");

  const { customersList } = customerDataSelector(useSelector);

  const handleAddNewCustomer = () => {
    dispatch(openModal({ key: CUSTOMER.key, props: {} }));
  };

  const handlePageChange = () => {};

  const handleMessageClick = useCallback(
    (data) => {
      const payload = getCustomerMailPayload(data);
      dispatch(sendMail({ payload }));
    },
    [dispatch]
  );

  const handleSettingClick = useCallback(
    (id, key) => {
      dispatch(editCustomer({ id, payload: { status: key } }));
    },
    [dispatch]
  );

  const handleEditClick = useCallback(
    (customerData) => {
      dispatch(openModal({ key: CUSTOMER.key, props: { customerData } }));
    },
    [CUSTOMER, dispatch]
  );

  const handleDeleteClick = useCallback(
    (id) => {
      dispatch(deleteCustomer({ id }));
    },
    [dispatch]
  );

  const handleClone = useCallback(
    ({ id }) => {
      dispatch(
        cloneCustomer({
          customer_id: id,
          callback: (token) => {
            window.open(
              `${process.env.REACT_APP_REDIRECT_URI}/?customer_token=${token}`,
              "_blank"
            );
          },
        })
      );
    },
    [dispatch]
  );

  const handleBatchStatusChange = (value) => {
    setBatchStatus(value);
  };

  const handleErrorLogClick = useCallback(
    (customerData) => {
      dispatch(openModal({ key: ERROR_LOGS.key, props: { customerData } }));
    },
    [dispatch, ERROR_LOGS]
  );

  const handleBatchApply = () => {
    let payload = batchStatusPayload(selectedUsers, batchStatus);
    dispatch(
      customerBatchUpdate({
        payload,
        callback: () => {
          setSelectedUsers([]);
          setBatchStatus("");
        },
      })
    );
  };

  const handleLoggingStatus = useCallback(
    ({ id, is_logging_allowed }) => {
      dispatch(
        editCustomer({
          id,
          payload: { is_logging_allowed },
        })
      );
      dispatch(updateCustomerList({ id, is_logging_allowed }));
    },
    [dispatch]
  );

  const handleAddSuperAdmin = useCallback(
    (record) => {
      dispatch(
        openModal({
          key: CREATE_SUPER_ADMIN.key,
          props: { customer_id: record.id },
        })
      );
    },
    [CREATE_SUPER_ADMIN.key, dispatch]
  );

  useEffect(() => {
    !customersList && dispatch(fetchCustomers());
  }, [dispatch, customersList]);

  const tableColumn = useMemo(
    () =>
      columnAndComponentFunctionReference(CUSTOMER_COLUMN, {
        handleClone,
        handleEditClick,
        handleDeleteClick,
        handleSettingClick,
        handleMessageClick,
        handleLoggingStatus,
        handleErrorLogClick,
        handleAddSuperAdmin,
        CUSTOMER_COLUMN_BADGE_COLOR,
      }),
    [
      CUSTOMER_COLUMN,
      handleClone,
      handleEditClick,
      handleSettingClick,
      handleMessageClick,
      handleDeleteClick,
      handleLoggingStatus,
      handleErrorLogClick,
      handleAddSuperAdmin,
      CUSTOMER_COLUMN_BADGE_COLOR,
    ]
  );

  const filteredCustomerList = useMemo(
    () =>
      search
        ? (customersList ?? []).filter(
            (customer) =>
              customer.id.toLowerCase().includes(search.toLowerCase()) ||
              customer.name.toLowerCase().includes(search.toLowerCase())
          )
        : customersList ?? [],
    [customersList, search]
  );

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedUsers.map(({ id }) => id),
      onChange: (_, selectedRows) => setSelectedUsers(selectedRows),
    }),
    [selectedUsers]
  );

  return (
    <div className="userContainer position-relative">
      <Row className="filter-head">
        <Col span={12}>
          <h2>Customers</h2>
        </Col>

        <Col
          style={{
            justifyContent: "end",
            display: "flex",
            alignItems: "center",
          }}
          span={12}
        >
          <Search
            className="mr-2 search"
            placeholder="Search by Name or ID"
            style={{
              width: 300,
            }}
            onSearch={(value) => setSearch(value)}
          />
        </Col>
      </Row>
      <Table
        className="cmn-table"
        columns={tableColumn}
        dataSource={withKeyDataSet(ID, filteredCustomerList)}
        scroll={{ y: "calc(70vh - 60px)" }}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        pagination={{
          currentPage,
          onChange: handlePageChange,
        }}
      />
      {selectedUsers.length > 0 && (
        <div className="selected-customers">
          <span className="selected-customers-count">
            {selectedUsers.length} Selected
            <Select
              className="ml-2"
              options={CUSTOMER_BULK_ACTION}
              placeholder="Select the Status"
              onChange={handleBatchStatusChange}
            />
          </span>
          <Button
            className="ml-2"
            onClick={handleBatchApply}
            disabled={!batchStatus}
          >
            Apply
          </Button>
        </div>
      )}
    </div>
  );
}

export default Customers;
