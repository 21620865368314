import React from "react";
import moment from "moment";
import { toast } from "react-toastify";

import CONFIG from "../config";
import Notification from "../../components/notification";
import dayjs from "dayjs";

export const idGenerator = () => {
  const timestamp = Date.now();
  const random = Math.random().toString(36).substring(2);
  const uniqueId = `${timestamp}-${random}`;
  return uniqueId;
};

export const isFalse = (value) => {
  if (Array.isArray(value)) return value.length === 0;

  switch (typeof value) {
    case "undefined":
      return true;
    case "object":
      if (value === null) return true;
      return Object.keys(value).length === 0;
    case "string":
      return value.trim() === "";
    case "number":
      return value === 0;
    case "boolean":
      return value === false;
    default:
      return false;
  }
};

export const getToken = (key) => {
  return localStorage.getItem(key);
};

export const setToken = (key, value) => {
  localStorage.setItem(key, value);
};

export const errorNotification = ({ title = "Error in API", message }) => {
  toast(
    <Notification
      src={CONFIG.NOTIFICATION_OBJECT.ERROR}
      title={title}
      message={message}
    />,
    {
      autoClose: 3000,
    }
  );
};

export const successNotification = ({
  title = "Success!",
  message = "Response submitted",
}) => {
  toast(
    <Notification
      src={CONFIG.NOTIFICATION_OBJECT.SUCCESS}
      title={title}
      message={message}
    />,
    {
      autoClose: 3000,
    }
  );
};

export const uppercaseFirstLetter = (str = "") => {
  return str?.replace(/(^\s*\w|\. \w)/g, function (c) {
    return c.toUpperCase();
  });
};

export const underscoreSeparator = (str) => {
  if (str?.includes("_")) return str.replaceAll("_", " ");
  return str;
};

export const titleGenerator = (key) => {
  return [underscoreSeparator, uppercaseFirstLetter].reduce(
    (result, fnc) => fnc(result),
    key
  );
};

export const offsetHeight = (ref) => {
  return ref?.current?.offsetHeight ?? 0;
};

export const columnAndComponentFunctionReference = (data, argObj) => {
  return data?.map(({ render: renderer, ...rest }) => {
    if (renderer) {
      return {
        ...rest,
        render: (value, data, index) => {
          return renderer(value, data, index, { ...argObj });
        },
      };
    } else return { ...rest };
  });
};

export const withKeyDataSet = (selector, data) => {
  return (
    data?.map((element) => ({ ...element, key: element?.[selector] })) ?? []
  );
};

export const groupOptions = (
  groupingLabel,
  groupingData = [],
  filterValueArr = []
) => {
  return groupingLabel.map(({ label, key }, index) => ({
    label,
    options: groupingData[index]?.reduce((acc, option) => {
      return filterValueArr.some(({ id, key }) => (id ?? key) === option.value)
        ? acc
        : [...acc, { type: key, ...option }];
    }, []),
  }));
};

export const optionCreator = (
  dataSet,
  labelSelector,
  valueSelector,
  subStringToRemove
) => {
  return (
    dataSet?.map?.((element) => ({
      label: labelSelector
        ? subStringToRemove
          ? removeSubString(element[labelSelector], subStringToRemove)
          : element[labelSelector]
        : element,
      value: valueSelector ? element[valueSelector] : element,
      key: valueSelector ? element[valueSelector] : element,
    })) ?? []
  );
};

export const getDetails = (dataSet, selector, comparingParameter) => {
  let obj = dataSet?.find((elem) => elem[selector] === comparingParameter) ?? {
    ...CONFIG.DEFAULT_PROFILE,
    id: idGenerator(),
  };
  return obj;
};

export const serialNumber = (index) => {
  return index + 1 + ". ";
};

export const isErrorPresent = (errorObject) => {
  return !Object.values(errorObject).every(({ errorMessage }) => !errorMessage);
};

export function isSubstring(substring, fullString) {
  const lowerCaseSubstring = substring.toLowerCase();
  const lowerCaseFullString = fullString.toLowerCase();
  return lowerCaseFullString.includes(lowerCaseSubstring);
}

export const booleanStringStatus = (value) => {
  if (value)
    if (value.toLowerCase() === "true" || value.toLowerCase() === "false") {
      return "success";
    } else if (
      "true".includes(value.toLowerCase()) ||
      "false".includes(value.toLowerCase())
    ) {
      return "warning";
    } else {
      return "error";
    }
  else return "primary";
};

export const createId = (data, selector, index) => {
  return `${data[selector]}-${index}`;
};

export const getFilteredValue = (list, selectorType) => {
  return list.filter(({ type }) => type === selectorType).map(({ key }) => key);
};

export const firstLetter = (string = "") => {
  return string?.charAt(0)?.toUpperCase() ?? "";
};

export const removeSubString = (string, subString) => {
  return string?.replace?.(subString, "") ?? "";
};

export const operatorDataForSelectedField = (list, selectedField) => {
  return list?.find(({ key }) => key === selectedField)?.operators ?? [];
};

export const showLogicOperator = (filter, index) => {
  let length = filter.length;
  return length > 1 && index !== length - 1;
};

export const filtersValidationValue = (filters) => {
  let length = filters.length;
  let { field, operator } = length ? filters[length - 1] : {};
  return { field, operator };
};

export const getPathKey = () => {
  return window.location.pathname.split("/")[1];
};

export const filterNavItems = (arr = [], allowedAccess = []) => {
  return arr.reduce((result, navItem) => {
    const { children, key } = navItem;
    if (children) {
      let tempChildren = filterNavItems(children, allowedAccess);
      return tempChildren.length
        ? [...result, { ...navItem, children: tempChildren }]
        : result;
    } else return allowedAccess.includes(key) ? [...result, navItem] : result;
  }, []);
};

export const filterGroup = (data, valueArr) => {
  return data.filter(({ value }) => !valueArr.some(({ id }) => id === value));
};

export function generateTimeArray() {
  const times = [];

  for (let h = 0; h < 24; h++) {
    for (let m = 0; m < 60; m += 15) {
      const hour = h === 0 ? 12 : h <= 12 ? h : h - 12;
      const paddedHour = hour.toString().padStart(2, "0");
      const period = h < 12 ? "AM" : "PM";
      const time = `${paddedHour}:${m === 0 ? "00" : m} ${period}`;
      times.push(time);
    }
  }

  return times;
}

export function splitArrayIntoSegments(arr, segmentSize) {
  const result = [];
  for (let i = 0; i < arr.length; i += segmentSize) {
    result.push(arr.slice(i, i + segmentSize));
  }
  return result;
}

export function convertWeeklyOutput(input) {
  const day = input.day;
  const timeList = input.timeList;

  if (!day || !timeList.length) return {};

  const output = {
    key: 0,
    label: day,
    children: (
      <div className="weekly-time">
        {timeList.map((time) => (
          <div className="week-time" key={time}>
            <h6>{time}</h6>
          </div>
        ))}
      </div>
    ),
  };

  return output;
}

export function doesDateExists(array, targetDate) {
  return array?.some(
    (date) => date.format("DD-MM-YYYY") === targetDate.format("DD-MM-YYYY")
  );
}

export function manageEndDates(dateArray, targetDate) {
  let result = [...dateArray];

  const existingDateIndex = dateArray.findIndex(
    (date) => date.format("DD-MM-YYYY") === targetDate.format("DD-MM-YYYY")
  );

  if (existingDateIndex === -1) result = [...result, targetDate];
  else result.splice(existingDateIndex, 1);

  return result;
}

export function getUTCTime(date, time) {
  return (
    moment(date?.format("DD-MM-YYYY") + " " + time, "DD-MM-YYYY hh:mm a")
      ?.toISOString()
      ?.split("T") ?? []
  );
}

export function convertDateFormat(originalDate) {
  const [day, month, year] = originalDate?.split("-");
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

export function getDayJsDate(date, time) {
  return dayjs(moment.utc(convertDateFormat(date) + "T" + time + ":00.000Z"));
}

export function getLocalTime(utc) {
  return utc.format("hh:mm a");
}

export const createCustomerPayload = ({
  name,
  username,
  mainContact,
  mainEmail,
  billingContact,
  billingEmail,
}) => {
  let data = {
    name,
    username,
    main_email: mainEmail,
    main_contact: mainContact,
    billing_email: billingEmail,
    billing_contact: billingContact,
  };

  return data;
};

export const batchStatusPayload = (arr, value) => {
  return { customers_list: arr.map(({ id }) => ({ id, status: value })) };
};

export const getCustomerMailPayload = ({ id }) => {
  return { id, wantsToSendMail: true };
};
