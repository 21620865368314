import { Button, Col, Form, Input, Modal, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../redux-toolkit/slice/modal";
import { createCustomerPayload } from "../../../utils/functions";
import {
  createCustomer,
  editCustomer,
} from "../../../redux-toolkit/slice/customer/asyncThunk";

function CustomerModal({ customerData }) {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleSave = () => {
    let valueObj = form.getFieldValue();

    if (Object.keys(valueObj).length < 6) {
      form.validateFields([
        "name",
        "username",
        "mainContact",
        "mainEmail",
        "billingContact",
        "billingEmail",
      ]);
    } else {
      let payload = createCustomerPayload(valueObj);
      customerData
        ? dispatch(
            editCustomer({
              id: customerData.id,
              payload,
              callback: handleCloseModal,
            })
          )
        : dispatch(createCustomer({ payload, callback: handleCloseModal }));
    }
  };

  useEffect(() => {
    if (customerData) {
      form.setFieldsValue({
        name: customerData.name,
        username: customerData.username,
        mainContact: customerData.main_contact,
        mainEmail: customerData.main_email,
        billingContact: customerData.billing_contact,
        billingEmail: customerData.billing_email,
      });
    }
  }, [form, customerData]);

  return (
    <Modal
      key={"Create New Customer"}
      title={customerData ? customerData.name : "Create New Customer"}
      centered
      className="common-modal"
      open={true}
      width={700}
      onCancel={handleCloseModal}
      footer={
        <div className="footer-btns">
          <Button className="primary-solid" onClick={handleSave}>
            Save
          </Button>
          <Button className="primary-outline" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      }
    >
      <Form form={form} name="form_item_path" layout="vertical">
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Row gutter={[12, 12]} className="mb-1">
              <Col xs={24} md={12}>
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Username!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Customer Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Name!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24}>
            <Row gutter={[12, 12]} className="mb-1">
              <Col xs={24} md={12}>
                <Form.Item
                  label="Main Contact"
                  name="mainContact"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Main Contact!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Main Email"
                  name="mainEmail"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24}>
            <Row gutter={[12, 12]} className="mb-1">
              <Col xs={24} md={12}>
                <Form.Item
                  label="Billing Contact"
                  name="billingContact"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Billing Contact!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Billing Email"
                  name="billingEmail"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default CustomerModal;
