import axios from "axios";
import { getToken, setToken } from "../utils/functions";
import CONFIG from "../utils/config";

const {
  REACT_APP_BASE_URL_SUPPORT,
  REACT_APP_BASE_URL_CUSTOMER,
  REACT_APP_BASE_URL_ERRORS,
} = process.env;

const SERVICE_TYPES = {
  SUPPORT: REACT_APP_BASE_URL_SUPPORT,
  CUSTOMER: REACT_APP_BASE_URL_CUSTOMER,
  ERRORS: REACT_APP_BASE_URL_ERRORS,
};

const api = axios.create({});

api.interceptors.request.use(async (config) => {
  const { service } = config;
  config.baseURL = SERVICE_TYPES[service];
  const token = getToken(CONFIG.TOKEN.ACCESS_TOKEN);
  if (token) {
    config.headers["Authorization"] = `${token}`;
  }
  delete config.service;
  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      try {
        const response = await axios.post(
          REACT_APP_BASE_URL_SUPPORT + CONFIG.API_URLS.GET_NEW_ACCESS_TOKEN_API,
          {
            refresh_token: getToken(CONFIG.TOKEN.REFRESH_TOKEN),
          }
        );
        const { access_token, refresh_token } = response.data;
        setToken(CONFIG.TOKEN.ACCESS_TOKEN, access_token);
        setToken(CONFIG.TOKEN.REFRESH_TOKEN, refresh_token);

        const originalRequest = error.config;
        originalRequest.headers["Authorization"] = `Bearer ${access_token}`;
        return api(originalRequest);
      } catch (refreshError) {
        setToken(CONFIG.TOKEN.ACCESS_TOKEN, "");
        setToken(CONFIG.TOKEN.REFRESH_TOKEN, "");
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default api;
