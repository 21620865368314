import { createSlice } from "@reduxjs/toolkit";

import INITIAL_STATE from "../../initialState";
import {
  createCustomer,
  createSuperAdminForCustomer,
  customerBatchUpdate,
  deleteCustomer,
  editCustomer,
  fetchCustomers,
} from "./asyncThunk";

export const customerSlice = createSlice({
  name: "customer",
  initialState: INITIAL_STATE.CUSTOMER,
  reducers: {
    updateCustomerList: (state, { payload }) => {
      let { id, is_logging_allowed } = payload;
      let editedIndex = state.customersList.findIndex(
        ({ id: customerId }) => customerId === id
      );
      state.customersList[editedIndex].is_logging_allowed = is_logging_allowed;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomers.fulfilled, (state, { payload }) => {
      state.error = false;
      state.customersList = payload;
    });

    builder.addCase(createCustomer.fulfilled, (state, { payload }) => {
      state.error = false;
      state.customersList = [payload, ...state.customersList];
    });

    builder.addCase(editCustomer.fulfilled, (state, { payload }) => {
      state.error = false;
      let editedIndex = state.customersList.findIndex(
        ({ id }) => id === payload.id
      );
      state.customersList[editedIndex] = payload;
    });

    builder.addCase(deleteCustomer.fulfilled, (state, { payload }) => {
      state.error = false;
      let editedIndex = state.customersList.findIndex(
        ({ id }) => id === payload.id
      );
      state.customersList[editedIndex] = payload;
    });

    builder.addCase(customerBatchUpdate.fulfilled, (state, { payload }) => {
      state.error = false;

      state.customersList = state.customersList.reduce((result, data) => {
        let editedResponse = payload.find(({ id }) => id === data.id);
        return [...result, editedResponse ?? data];
      }, []);
    });

    builder.addCase(
      createSuperAdminForCustomer.fulfilled,
      (state, { payload }) => {
        state.error = false;
        let editedIndex = state.customersList.findIndex(
          ({ id }) => id === payload.id
        );
        state.customersList[editedIndex] = payload;
      }
    );
    builder.addCase(createSuperAdminForCustomer.rejected, (state) => {
      state.error = true;
    });
  },
});

export default customerSlice.reducer;

export const { updateCustomerList } = customerSlice.actions;
